import {
  StatusIconGray, StatusIconGreen, StatusIconRed, StatusIconYellow
} from 'assets/icons'
import { ImagesPng } from 'assets/images'
import * as CryptoJS from 'crypto-js'
import L from 'leaflet'
import { MAX_ZOOM_LEVEL, ZOOM_LEVEL, LOGOUT_ERR_MSG } from 'utils/constants'

export const iconMarkerImage = (friend, html) => L.divIcon({
  html,
  popupAnchor: [0, -15],
  iconSize: friend === true ? new L.Point(30, 49) : new L.Point(27.5, 46),
  iconAnchor: friend === true ? [15, 41] : [14, 42],
  className: 'leaflet-div-icon-marker'
})

export const htmlTemplate = (avatar, marker, friend) => {
  let html = `<img src="${ImagesPng.DefaultImage}" width="27px" height="27px" marginTop="5px" class="avatar" alt="marker" /><img src="${marker}" width="27.5px" height="46px" class="marker-image" alt="marker" />`
  if (friend === 'accepted' && avatar !== ImagesPng.DefaultImage) {
    html = `<img src="${avatar}" width="27px" height="27px" marginTop="5px" class="avatar-friend" alt="marker" /><img src="${marker}" width="27.5px" height="46px" class="marker-image-friend" alt="marker" />`
    return html
  } if (friend === 'accepted' && avatar === null) {
    html = `<img src="${ImagesPng.DefaultImage}" width="27px" height="27px" marginTop="5px" class="avatar-friend-no-avatar" alt="marker" /><img src="${marker}" width="27.5px" height="46px" class="marker-image-friend" alt="marker" />`
    return html
  }
  return html
}

export const createMarkerColor = (date, avatar, friends) => {
  const lastLoginDate = date.diff(new Date(), 'week')
  const markerAvatar = avatar

  if (lastLoginDate <= -2 && lastLoginDate > -52) {
    if (friends === 'accepted') {
      return iconMarkerImage(true, htmlTemplate(markerAvatar, ImagesPng.GoldMarker, friends))
    }
    return iconMarkerImage(false, htmlTemplate(markerAvatar, ImagesPng.GoldMarker, friends))
  }

  if (lastLoginDate <= -52 && lastLoginDate > -100) {
    if (friends === 'accepted') {
      return iconMarkerImage(true, htmlTemplate(markerAvatar, ImagesPng.RedMarker, friends))
    }
    return iconMarkerImage(false, htmlTemplate(markerAvatar, ImagesPng.RedMarker, friends))
  }

  if (lastLoginDate <= -100) {
    if (friends === 'accepted') {
      return iconMarkerImage(true, htmlTemplate(markerAvatar, ImagesPng.BlackMarker, friends))
    }
    return iconMarkerImage(false, htmlTemplate(markerAvatar, ImagesPng.BlackMarker, friends))
  }

  if (friends === 'accepted') {
    return iconMarkerImage(true, htmlTemplate(markerAvatar, ImagesPng.GreenMarker, friends))
  }

  return iconMarkerImage(false, htmlTemplate(markerAvatar, ImagesPng.GreenMarker, friends))
}

export const statusMarkerColor = (date) => {
  const lastLoginDate = date.diff(new Date(), 'week')

  if (lastLoginDate <= -2 && lastLoginDate > -52) {
    return <StatusIconYellow />
  }
  if (lastLoginDate <= -52 && lastLoginDate > -100) {
    return <StatusIconRed />
  }

  if (lastLoginDate <= -100) {
    return <StatusIconGray />
  }

  return <StatusIconGreen />
}

export const getUrlParams = (fastParams, secondsParams) => {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  return [params.get(fastParams), params.get(secondsParams)]
}

export const flyLocation = async (mapRef, lat = 0, lng = 0, getCenter = false, isStatic = true) => {
  // see https://leafletjs.com/reference.html#map-setview
  const latlng = L.latLng(lat, lng)
  const zoom = isStatic ? MAX_ZOOM_LEVEL : ZOOM_LEVEL
  if (mapRef?.current) {
    if (getCenter) {
      const mapCenter = mapRef.current.getCenter()

      const latDiff = Math.abs(mapCenter.lat - lat)
      const lngDiff = Math.abs(mapCenter.lng - lng)

      if (latDiff > 0.02 && lngDiff > 0.02) {
        mapRef.current.flyTo(latlng, zoom, { animate: true })
      } else {
        mapRef.current.setView(latlng, zoom, { animate: true })
      }
    } else {
      mapRef.current.setView(latlng, zoom, { animate: true })
    }
  }
}

// Storage Helpers
export const isAuth = () => !!JSON.parse(localStorage.getItem('credentials'))?.access_token

export const setStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value))

export const getStorage = (key) => JSON.parse(localStorage.getItem(key))

export const removeStorage = (key) => localStorage.removeItem(key)

export const clearStorage = () => localStorage.clear()

export const subtractYears = (date, years) => {
  date.setFullYear(date.getFullYear() - years)
  return date
}

export const connectionCount = ({ count, isVisible }) => {
  if (!isVisible) {
    return count
  }

  if (count === 0) {
    return '0'
  }

  if (count <= 25) {
    return '0-25'
  }

  if (count > 25 && count <= 50) {
    return '25-50'
  }

  if (count > 50 && count <= 100) {
    return '50-100'
  }

  if (count > 100 && count <= 200) {
    return '100-200'
  }

  if (count > 200 && count <= 500) {
    return '200-500'
  }

  if (count > 500) {
    return '500+'
  }

  return 0
}

export const getParsedPassword = (base64Password) => {
  let encryptStr = CryptoJS.enc.Base64.parse(base64Password)
  let encryptData = encryptStr.toString(CryptoJS.enc.Utf8)
  encryptData = JSON.parse(encryptData)
  let iv = CryptoJS.enc.Base64.parse(encryptData.iv)
  let decrypted = CryptoJS.AES.decrypt(
    encryptData.value,
    CryptoJS.enc.Base64.parse(process.env.REACT_APP_DECODE_KEY),
    {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  )

  decrypted = CryptoJS.enc.Utf8.stringify(decrypted)

  const regex = /"([^"]*)"/
  const match = decrypted.match(regex)
  if (match && match.length > 1) {
    return match[1]
  }
  return null
}

export const getParsedData = (encryptedData) => {
  let encryptStr = CryptoJS.enc.Base64.parse(encryptedData)
  let encryptData = encryptStr.toString(CryptoJS.enc.Utf8)
  encryptData = JSON.parse(encryptData)
  let iv = CryptoJS.enc.Base64.parse(encryptData.iv)
  let decrypted = CryptoJS.AES.decrypt(
    encryptData.value,
    CryptoJS.enc.Base64.parse(process.env.REACT_APP_DECODE_KEY),
    {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  )
  decrypted = CryptoJS.enc.Utf8.stringify(decrypted)

  if (decrypted !== 's:2:"[]";') {
    let fixFormat = decrypted.split('[{')[1]
    fixFormat = '[{' + fixFormat.split('}]')[0] + '}]'

    return JSON.parse(fixFormat)
  }

  return []
}

export const handleClickScroll = (scrollId) => {
  const element = document.getElementById(scrollId)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

export const dateNumberFormat = (date) => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return +`${year}${month}${day}`
}

export const createValidFormatData = (data) => {
  const validQuoteData = []
  // eslint-disable-next-line guard-for-in
  for (let key in data) {
    validQuoteData.push({ id: key, value: data[key] })
  }
  return validQuoteData
}

export const howManyDayAgo = (date) => {
  const millisecondsPerDay = 86400000
  const date1 = new Date(date)
  const date2 = new Date()
  const diffInMilliseconds = date2 - date1
  const diffInDays = Math.round(diffInMilliseconds / millisecondsPerDay)

  return diffInDays > 0 ? `${diffInDays} days ago` : 'Today'
}

export const generateConverseJID = (data) => {
  const profileTypeCode = {
    friends: 0,
    dating: 1,
    professional: 2
  }

  return `${data.id}-${profileTypeCode[data.profile_type]}@${process.env.REACT_APP_XMPP_CHAT_URL}`
}

export const generateDifferenceByDay = (date) => {
  // Convert the "sent_at" timestamp to a Date object
  const sentAt = new Date(date)

  // Get the current date and time
  const now = new Date()

  // Calculate the difference between "sent_at" and the current time in milliseconds
  const timeDiff = now.getTime() - sentAt.getTime()

  // Calculate the difference in days
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24))

  // Define some constants for the time intervals
  const ONE_DAY = 1000 * 60 * 60 * 24
  const ONE_WEEK = ONE_DAY * 7

  // Determine the appropriate string to display based on the time difference
  let dateString
  if (daysDiff === 0) {
    dateString = 'Today'
  } else if (daysDiff === 1) {
    dateString = 'Yesterday'
  } else if (timeDiff < ONE_WEEK) {
    dateString = sentAt.toLocaleDateString()
  } else {
    // dateString = `${sentAt.toLocaleDateString()} ${sentAt.toLocaleTimeString()}`
    dateString = `${sentAt.toLocaleDateString()}`
  }
  return dateString
}

export const hideCounter = () => {
  const ZoomCont = document.querySelector('.leaflet-control-zoom')
  const filterData = document.querySelector('.filter-data')
  const markerCountBox = document.querySelector('.marker-count')
  ZoomCont.style.opacity = 0
  ZoomCont.style.pointerEvents = 'none'
  filterData.style.opacity = 0
  filterData.style.pointerEvents = 'none'
  markerCountBox.style.opacity = 0
  markerCountBox.style.pointerEvents = 'none'
}

export const showCounter = () => {
  const ZoomCont = document.querySelector('.leaflet-control-zoom')
  const filterData = document.querySelector('.filter-data')
  const markerCountBox = document.querySelector('.marker-count')
  ZoomCont.style.opacity = 1
  ZoomCont.style.pointerEvents = 'auto'
  filterData.style.opacity = 1
  filterData.style.pointerEvents = 'auto'
  markerCountBox.style.opacity = 1
  markerCountBox.style.pointerEvents = 'auto'
}

// NOTE: downloadable app does not know hosted url.
// window.location.href returns local file with file:// protocol
// for sharing url outside the application we use the translation
export const translateFromLocalUrl = () => {
  const newUrl = new URL(window.location.href)
  return process.env.REACT_APP_FRONTEND_URL + '/' + newUrl.search
}

export const checkForceLogout = (errMsg) => {
  let goLogOut = 0
  LOGOUT_ERR_MSG.forEach((index) => {
    if (errMsg.includes(index)) {
      goLogOut = 1
    }

    return false
  })

  return goLogOut
}

export const resizeImage = (image, useOn, callback) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  let newWidth = 0
  let newHeight = 0

  if (useOn === 'cp') {
    const maxDimension = 2000

    const scale = Math.min(maxDimension / image.width, maxDimension / image.height)

    newWidth = image.width * scale
    newHeight = image.height * scale
  } else if (useOn === 'pp') {
    const maxDimension = 700

    const scale = Math.min(maxDimension / image.width, maxDimension / image.height)

    newWidth = image.width * scale
    newHeight = image.height * scale
  }

  canvas.width = newWidth
  canvas.height = newHeight
  ctx.drawImage(
    image,
    0,
    0,
    image.width,
    image.height,
    0,
    0,
    newWidth,
    newHeight
  )

  canvas.toBlob((blob) => {
    if (blob) {
      callback(blob)
    } else {
      console.error('Failed to create Blob from the canvas')
      callback(null)
    }
  }, 'image/png', 1)
}

export const isLiteralNumber = (value) => typeof value === 'number' || (typeof value === 'string' && !Number.isNaN(Number(value)))

export const isIOS = () => /iPhone/.test(navigator.userAgent) && !window.MSStream
